import { SnackBar } from "@/components/snackBar/SnackBar";
import { breakpoints } from "@/styles/themes/extend";
import { useMediaQuery, type ToastPositionWithLogical, useToast } from "@chakra-ui/react";
import {
  createContext,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

interface ErrorObject {
  message: string;
}

export interface ErrorContextValue {
  error: null | ErrorObject;
  handleError: (error: ErrorObject) => void;
  clearError: () => void;
}

export const ErrorContext = createContext<ErrorContextValue>({
  error: null,
  handleError: () => {},
  clearError: () => {},
});

export const ErrorProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const toast = useToast();
  const [isPC] = useMediaQuery([`(min-width: ${breakpoints.pc})`]);
  const [error, setError] = useState<ErrorContextValue["error"]>(null);
  const prevError = useRef<ErrorObject | null>(null);

  const position: ToastPositionWithLogical = useMemo(() => (isPC ? "bottom-right" : "bottom"), [isPC]);

  const showErrorToast = useCallback(
    (message: string) => {
      toast({
        position,
        containerStyle: {
          width: "100%",
          maxWidth: "100%",
        },
        // eslint-disable-next-line @typescript-eslint/unbound-method -- intended
        render: ({ onClose }) => (
          <SnackBar variant="error" onClose={onClose}>
            {message}
          </SnackBar>
        ),
        onCloseComplete: () => {
          setError(null);
          prevError.current = null;
        },
      });
    },
    [position, toast],
  );

  const handleError = useCallback(({ message }: ErrorObject) => {
    setError({ message });
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  useEffect(() => {
    if (!error) return undefined;
    // 重複したエラーの表示は回避する
    if (prevError.current?.message === error.message) return undefined;

    prevError.current = error;

    showErrorToast(error.message);
  }, [error, showErrorToast]);

  return <ErrorContext.Provider value={{ error, handleError, clearError }}>{children}</ErrorContext.Provider>;
};
