import { type TextProps } from "@chakra-ui/react";
import { type TypographyBreakpointsVariant, type TypographyVariant } from "./type";

export interface TypographyVariantValue {
  readonly fontSize: TextProps["fontSize"];
  readonly fontWeight: TextProps["fontWeight"];
  readonly textDecoration: TextProps["textDecoration"];
  readonly lineHeight: TextProps["lineHeight"];
}

export const typographyValiant: Record<TypographyVariant, TypographyVariantValue> = {
  heading1: {
    fontSize: "40px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  heading2: {
    fontSize: "32px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  heading2b: {
    fontSize: "32px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: "40px",
  },
  heading3: {
    fontSize: "24px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  heading4: {
    fontSize: "20px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  heading4b: {
    fontSize: "20px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: "40px",
  },
  heading5: {
    fontSize: "16px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  body1: {
    fontSize: "16px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: "32px",
  },
  body1b: {
    fontSize: "16px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  body2: {
    fontSize: "14px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  body2b: {
    fontSize: "14px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  body3: {
    fontSize: "12px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  body3b: {
    fontSize: "12px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  body4: {
    fontSize: "10px",
    fontWeight: undefined,
    textDecoration: "none",
    lineHeight: undefined,
  },
  body4b: {
    fontSize: "10px",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
};

const switchVariant = (variant: TypographyVariant): TypographyVariantValue => {
  switch (variant) {
    case "heading1":
      return typographyValiant.heading1;
    case "heading2":
      return typographyValiant.heading2;
    case "heading2b":
      return typographyValiant.heading2b;
    case "heading3":
      return typographyValiant.heading3;
    case "heading4":
      return typographyValiant.heading4;
    case "heading4b":
      return typographyValiant.heading4b;
    case "heading5":
      return typographyValiant.heading5;
    case "body1":
      return typographyValiant.body1;
    case "body1b":
      return typographyValiant.body1b;
    case "body2":
      return typographyValiant.body2;
    case "body2b":
      return typographyValiant.body2b;
    case "body3":
      return typographyValiant.body3;
    case "body3b":
      return typographyValiant.body3b;
    case "body4":
      return typographyValiant.body4;
    case "body4b":
      return typographyValiant.body4b;
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- intended
    default:
      throw new Error("Invalid variant");
  }
};

export const injectTypographyVariant = (
  variant: TypographyVariant | TypographyBreakpointsVariant,
): TypographyVariantValue => {
  if (typeof variant === "string") {
    return switchVariant(variant);
  } else {
    return {
      fontSize: {
        sp: switchVariant(variant.sp ?? "heading1").fontSize as string,
        tablet: switchVariant(variant.tablet ?? "heading1").fontSize as string,
        pc: switchVariant(variant.pc ?? "heading1").fontSize as string,
      },
      fontWeight: {
        sp: switchVariant(variant.sp ?? "heading1").fontWeight as string,
        tablet: switchVariant(variant.tablet ?? "heading1").fontWeight as string,
        pc: switchVariant(variant.pc ?? "heading1").fontWeight as string,
      },
      textDecoration: {
        sp: switchVariant(variant.sp ?? "heading1").textDecoration as string,
        tablet: switchVariant(variant.tablet ?? "heading1").textDecoration as string,
        pc: switchVariant(variant.pc ?? "heading1").textDecoration as string,
      },
      lineHeight: {
        sp: switchVariant(variant.sp ?? "heading1").lineHeight as string,
        tablet: switchVariant(variant.tablet ?? "heading1").lineHeight as string,
        pc: switchVariant(variant.pc ?? "heading1").lineHeight as string,
      },
    };
  }
};
